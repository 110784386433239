import request from '@/utils/request'


// 查询房间信息列表
export function listRoom(query) {
  return request({
    url: '/biz/roomInfo/list',
    method: 'get',
    params: query
  })
}

// 查询房间信息分页
export function pageRoom(query) {
  return request({
    url: '/biz/roomInfo/page',
    method: 'get',
    params: query
  })
}

// 查询房间信息详细
export function getRoom(data) {
  return request({
    url: '/biz/roomInfo/detail',
    method: 'get',
    params: data
  })
}

// 新增房间信息
export function addRoom(data) {
  return request({
    url: '/biz/roomInfo/add',
    method: 'post',
    data: data
  })
}

// 修改房间信息
export function updateRoom(data) {
  return request({
    url: '/biz/roomInfo/edit',
    method: 'post',
    data: data
  })
}

// 删除房间信息
export function delRoom(data) {
  return request({
    url: '/biz/roomInfo/delete',
    method: 'post',
    data: data
  })
}
// 封禁房间，按结算房间处理
export function dissolutionRoom(data) {
  return request({
    url: '/biz/roomInfo/dissolutionRoom',
    method: 'post',
    data: data
  })
}

export function cancelLock(data) {
  return request({
    url: '/biz/roomInfo/cancelLock',
    method: 'post',
    data: data
  })
}

// 房间信息配置
export function editSet(data) {
  return request({
    url: '/biz/roomInfo/editSet',
    method: 'post',
    data: data
  })
}
// 结束PK
export function roomEndPK(data) {
  return request({
    url: '/biz/roomInfo/endPK',
    method: 'post',
    data: data
  })
}
