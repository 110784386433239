<template>
  <a-drawer width="30%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="房间Logo" prop="logo" >
        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          :showUploadList="false"
          :customRequest='fileUploadRequest'
          :beforeUpload="beforeUpload"
          @change="handleChange"
        >
          <img v-if="form.logo" :src="form.logo" alt="图片" style="height:104px;max-width:300px"/>
          <div v-else>
            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="房间ID" prop="roomNo" >
        <a-input-number min="10000" v-model="form.roomNo" placeholder="请输入房间ID" style="width:200px;"    />
      </a-form-model-item>
      <a-form-model-item label="所属公会" prop="guildName" >
       {{form.guildName}}
      </a-form-model-item>
      <a-form-model-item label="Id" prop="id"  style="display:none;">
        <a-input v-model="form.id" placeholder="" />
      </a-form-model-item>
      <a-form-model-item label="房间名称" prop="name" >
        <a-input v-model="form.name" placeholder="请输入房间名称" style="width:200px;" />
      </a-form-model-item>

      <a-form-model-item label="热门权重" prop="searchWeight"  >
        <a-input-number v-model="form.searchWeight" placeholder="请输入搜索权重" style="width:200px;"  />
      </a-form-model-item>
      <a-form-model-item label="房间虚拟人数" prop="virtualNum"   >
        <a-input-number v-model="form.virtualNum" placeholder="请输入虚拟人数" style="width:200px;" />
      </a-form-model-item>
      <a-form-model-item label="房主分成占比" prop="roomDivideInto"   >
        <a-input-number min="0" max="100" precision="2"  v-model="form.roomDivideInto" placeholder="请输入房主分成占比" style="width:200px;" /> %
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getRoom ,editSet} from '@/api/biz/roomInfo'
import UploadFileToOSS from '@/utils/upload-file-to-oss'

export default {
  name: 'CreateForm',
  props: {
    isBlackRoomOptions: {
      type: Array,
      required: true
    },
    isLockOptions: {
      type: Array,
      required: true
    },
    isAutoLockOptions: {
      type: Array,
      required: true
    },
    addMusicSwitchOptions: {
      type: Array,
      required: true
    },
    pkStatusOptions: {
      type: Array,
      required: true
    },
    statusOptions: {
      type: Array,
      required: true
    },
    publicScreenOptions: {
      type: Array,
      required: true
    },
    giftEffectOptions: {
      type: Array,
      required: true
    },
    isSysRoomOptions: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data () {
    return {
      uploadLoading:false,
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        roomNo: null,

        guildId: null,

        userId: null,

        name: null,

        type: null,

        theme: null,

        isBlackRoom: null,

        logo: null,

        numLimit: null,

        isLock: null,

        password: null,

        isAutoLock: null,

        gangStatus: 0,

        playStatus: 0,

        backImg: null,

        addMusicSwitch: null,

        userApplySwitch: null,

        announcement: null,

        searchWeight: null,

        pkStatus: null,

        status: null,

        publicScreen: null,

        minJewel: null,

        giftEffect: null,

        isSysRoom: null,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        roomNo: [
          { required: true, message: '请输入房间ID', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入房间名称', trigger: 'blur' }, { max: 10, message: '最多输入10个字符', trigger: 'blur'}
        ],

        searchWeight: [
          { required: true, message: '请输入热门权重', trigger: 'blur' }
        ],
        virtualNum: [
          { required: true, message: '请输入虚拟人数', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {

    fileUploadRequest(fileInfo) {
      this.uploadLoading = true

      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          // Log.info('multi-img-upload', res);
          const ossUrl = res[0].url;
          fileInfo.file.ossUrl = ossUrl;
          fileInfo.file.ossName = ossUrl;
          fileInfo.onSuccess();
        },
      });
    },
    beforeUpload: function (file) {
      return new Promise((resolve, reject) => {
        if(file.size>5*1024*1000){
          this.$message.warning("图片大小不能超过5M----")
          return reject(false)
        }

        var fileType = file.type;
        if (fileType.indexOf('image') < 0) {
          this.$message.warning('请上传图片');
          return reject(false);
        }
        resolve(true)
      });
    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.uploadLoading = true
        return
      }
      if (info.file.status === 'done') {
        // let response = info.file.response;
        this.uploadLoading = false;
        this.form.logo = info.file.originFileObj.ossUrl;
      }
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
      this.form={}
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        roomNo: null,
        guildId: null,
        userId: null,
        name: null,
        type: null,
        theme: null,
        isBlackRoom: null,
        logo: null,
        numLimit: null,
        isLock: null,
        password: null,
        isAutoLock: null,
        gangStatus: 0,
        playStatus: 0,
        backImg: null,
        addMusicSwitch: null,
        userApplySwitch: null,
        announcement: null,
        searchWeight: null,
        pkStatus: null,
        status: null,
        publicScreen: null,
        minJewel: null,
        giftEffect: null,
        isSysRoom: null,
        createTime: null,
        remark: null,
        virtualNum: null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      let that=this;
      // that.reset()
      that.form=JSON.parse(JSON.stringify({}));
      that.formType = 1
      that.open = true
      that.formTitle = '房间参数配置'
      that.form= JSON.parse(JSON.stringify(row));
    },
    /** 修改按钮操作 */
    // handleUpdate (row, ids) {
    //   this.reset()
    //   this.formType = 2
    //   const id = row ? row.id : ids
    //   getRoom({"id":id}).then(response => {
    //     this.form = response.data
    //     this.open = true
    //     this.formTitle = '房间参数配置'
    //   })
    // },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            editSet(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
